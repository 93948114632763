.background-image-lazy {
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    width: 100%;
    object-fit: cover;
    &_hide {
      display: none;
    }
  }

  &__loader {
    margin: 5px !important;
  }
}
