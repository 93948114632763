.gps-stats {
 padding: 16px;

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  &__item {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
