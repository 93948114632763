.page {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: $header-height;
    font-size: 16px;
    width: 100%;
    background: $layout-header-background-color;
    color: $layout-header-color;
    flex-shrink: 0;

    .open > .dropdown-toggle.btn-default {
      color: $layout-header-color !important;
      background-color: $color-gray !important;
      border: 0 !important;
      border-radius: 0 !important;
    }

    .nav-pills {
      display: flex;
      align-items: center;

      > li {
        padding: 0 10px;
      }

      .dropdown-menu {
        margin: 0;
        padding: 0;
      }
    }

    .nav-pills > li + li {
      margin-left: 0;
    }

    .form-control {
      font-size: 16px;
    }

    .nav {
      .header__link {
        color: $layout-header-color;
        font-size: 18px;
      }

      select {
        border: 0;
        color: $color-white;
        background: transparent;
        padding: 0 10px;
        height: 40px;
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 5px 15px;
    background: $layout-footer-background-color;
    color: $layout-footer-color;
  }

  &__main {
    flex: 1;
  }

  &__content {
    overflow-y: auto;
    flex: 1;
    display: flex;
    background: $layout-content-background-color;
    min-width: 0;
  }

  &__nav {
    min-width: 0;
    overflow-y: auto;

    li {
      width: 100%;
    }

    a {
      color: $color-white;
      display: block;
      height: 100%;

      &.active,
      &:hover,
      &:active,
      &:focus {
        color: $color-black;
        border-radius: 0;
        background-color: $color-gray-light;
        text-decoration: none;
      }
    }

    &__heading {
      color: $color-logo-yellow;
      padding: 0 15px;
    }

    .nav-pills {
      width: 210px;
      overflow-y: auto;

      &--collapsed {
        width: 70px;

        li {
          width: 70px;
          overflow: hidden;
        }
      }

      .fa,
      .far,
      .fas {
        width: 20px;
        font-size: 20px;
        margin-right: 20px;
        margin-left: 10px;
        text-align: center;
      }
    }
  }

  &__content-wrapper {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: $layout-content-wrapper-background-color;
    overflow: auto;
  }
}

.page-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $panel-height;
    cursor: pointer;
    background: #555;
    &:focus {
      outline: none;
    }

    i {
      color: $color-white;
      font-size: 20px;
    }
  }
}

.header {
  &__select {
    background: $color-gray;
    border: 0;
    border-radius: 0;
    color: $layout-header-color;
    font-size: 16px;
    padding: 0;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &_no-elepsis {
      overflow: visible;
    }

    &:hover,
    &:focus {
      color: $layout-header-color !important;
      background-color: $color-gray !important;
      border: 0 !important;
      border-radius: 0 !important;
    }

    &:after {
      color: $color-logo-yellow;
    }

    &_no-caret {
      &:after {
        display: none;
      }
    }
  }
  &__link {
    i {
      color: rgb(245, 203, 92);
    }
  }
  &__user {
    margin: 0;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__button {
    cursor: pointer;
  }
}
