.works {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &_disabled {
    font-size: 14px;
    margin: 16px;

    &>span {
      background: #f5cb5c80;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    color: $color-green;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__img-container {
    padding: 10px;
    margin: -10px;

    &_disabled {
      pointer-events: none;
    }
  }

  p {
    margin: 0;
  }

  .customer-filters {
    padding: 0;
    background: #fff;

    .breadcrumb {
      padding: 0;
      background: #fff;
    }
  }
}

.works-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.works-icon-name {
  display: flex;
  align-items: center;
}

.work-name {
  font-size: 16px;
  color: $color-gray-text;
}

.works-mixture {
  border: 1px solid $color-gray-light;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;

  &__normal-field {
    width: 140px;
    & .label-with-select-unit, .label-with-selected-unit {
      padding-left: 5px;
    }
  }

  &__base-field {
    width: calc(100% - 173px);
  }

  &__fields {
    margin-bottom: 10px;
    border-bottom: 1px solid $color-gray-light;
    display: flex;
    align-items: flex-start;

    .form-group {
      width: 100%;
      padding: 0 5px;
    }

    &--column {
      flex-direction: column;
    }
  }

  &__btn-group {
    margin-bottom: 15px;
    margin-top: 26px;

    .btn-primary-color {
      color: #4cae4c;
    }

    .btn-danger-color {
      color: #d9534f;
    }
  }
}

.work {
  display: flex;
  width: 100%;
  font-size: 16px;
  margin-bottom: 4px;
  margin-left: -16px;
  padding: 2px 24px 2px 16px;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none !important;

  &__faded {
    color: $color-gray-text;
  }

  &__disabled {
    pointer-events: none;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    border: 1px solid $color-green;
  }

  &-info {
    padding-left: 10px;
  }
}

.work-date-status {
  width: 180px;
  flex-shrink: 0;
}

.work-not-found {
  font-size: 14px;
  color: $color-gray-text;
}

.work-field {
  &--hidden {
    display: none;
  }
}

.work-description-loader {
  margin-top: 6px;
}

.work-description-comment {
  white-space: pre-wrap;

  &__bold {
    font-weight: bold;
  }
}

.work-status-wrapper {
  display: flex;
}

.work-season-plan-icon {
  width: 25px;
  height: 25px;
  font-size: 14px;
}

.work-recommendation {
  display: flex;

  &__title {
    padding-right: 10px;

    &:before {
      content: '\f075';
      text-align: left;
      vertical-align: middle;
      display: inline-block;
      width: 15px;
      height: 15px;
      line-height: 12px;
      margin-right: 3px;
      font-family: 'Font Awesome 5 Free';
      font-size: 10px;
      font-weight: 900;
    }
  }
}

.work-recommendation-dropdown {
  padding: 5px;
}

.plan-actual-diff {
  font-size: 12px;
  color: $color-gray-text;
}

.work-tracks-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.work-tracks-info-btn {
  margin-top: 12px;
  align-items: center;
  display: flex;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: #9b9b9b;
  text-underline-offset: 3px;
}

.unattached-tracks {
  display: flex;
  width: 100%;

  &__group {
    padding: 0;
    width: 100%;
  }

  &__group-item {
    display: flex;
    width: 100%;
    font-size: 16px;
    margin-bottom: 4px;
    margin-left: -16px;
    padding: 2px 24px 2px 16px;
    cursor: pointer;
    border: 1px solid transparent;
    outline: none !important;

    &:hover {
      border: 1px solid #069c61;
    }
  }
}

.create-workrecord-form {
  &__range {
    display: flex;
    align-items: center;

    &-divider {
      margin: 0 4px;
    }

    & .input-ui__error {
      display: none;
    }

    &_hide-input {
      & .input-ui__input-wrap {
        display: none;
      }
    }
  }

  &__label {
    font-weight: 700;
  }
}

.work-description-machinery{
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      &:before {
        content: "-";
        position: relative;
        margin-right: 3px;
      }
    }
  }
}
