$padding: 16px;
$small-padding: 10px;

.scout-container {
  height: 100%;
  width: 100%;
  padding: calc(#{$padding} / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: $color-gray-light;
  overflow-y: auto;
  position: relative;

  &_small-padding {
    padding: calc(#{$small-padding} / 2);
  }
}

.scout-last-container {
  height: calc(100% - 112px);
}

.scout-plan__status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: grey;
  margin-right: 6px;

  &.DONE {
    background: #069c61;
  }
  &.PROGRESS {
    background: #83a6ed;
  }
  &.LOST {
    background: red;
  }
}

.scout-card-column {
  height: 100%;
  width: 50%;
}

.scout-card {
  height: calc(50% - #{$padding});
  min-height: 200px;
  padding: $padding;
  margin: calc(#{$padding} / 2);
  background: $color-white;
  flex-basis: calc(50% - #{$padding});
  overflow-y: auto;

  &_small-padding {
    margin: calc(#{$small-padding} / 2);
    border-radius: 4px;
  }

  @media (max-width: 992px) {
    flex-basis: calc(100% - #{$padding});
  }

  &--no-padding {
    padding: 0;
  }

  &--full-height {
    height: calc(100% - #{$padding} - #{$padding} / 2);
  }

  &__flex-wrap {
    display: flex;
  }

  &__element-td {
    padding: 4px;

    &_no-padding-left {
      padding-left: 0;
    }
  }

  &__flex {
    flex: 1;
  }

  &_full-height {
    height: 100%;
  }

  &_fix-min-height {
    min-height: 523px;
  }

  &__grey-sub-title {
    margin-top: 12px;
    margin-bottom: 6px;
  }

  &__field-item {
    border-bottom: 1px dashed #9b9b9b;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__element {
    margin-bottom: 4px;

    &_flex {
      display: flex;
    }

    & .scout-card__element-title {
      min-width: 220px;
    }
  }

  &__grey-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 13px;
    font-size: 16px;
    color: #9b9b9b;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #ececec
  }

  &--absolute {
    position: absolute;
    height: calc(75% - #{$padding});
    width: 50%;
    bottom: #{$padding};
    right: 0;
    border: 1px solid $color-gray-light;
    border-radius: 4px;
    box-shadow: 0 1px 5px $color-gray-light;
    margin: 0;
    z-index: 100;
  }
}
