.image {
  display: flex;
  align-items: center;
  padding: 10px;

  &__info {
    flex: 1;
  }

  &__ttn-thumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }

    &__img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 250px;
      height: 250px;

      &--small {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
}
