.stage-item {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 200px;
  padding: 10px;

  img {
    width: 150px;
  }
}

.stage-item-container {
  display: flex;
  padding: 10px;
  overflow-x: auto;
}

.stage-item-title {
  padding-top: 10px;
  padding-left: 10px;
}
