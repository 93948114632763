.rotation-year-changer {

  &-arrow {
    width: 18px;
    margin: 0 8px;
    cursor: pointer;
    filter: grayscale(100%);

    &-disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &.left {
      transform: rotate(90deg);
    }

    &.right {
      transform: rotate(270deg);
    }
  }
}
