.crop-color {
  &__block {
    content: '';
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    line-height: 10px;
    margin-right: 5px;

    &_circle {
      border-radius: 50%;
    }

    &_sm {
      width: 10px;
      height: 10px;
    }

    &_lg {
      width: 40px;
      height: 40px;
    }

    &_square {
      border-radius: 0;
    }
  }
}

.crops-colors {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.crops-colors-form {
  &__block {
    display: flex;
    margin-bottom: 20px;
  }
}

.crops-colors-form-crop-color {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.crop-colors-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  &__item {
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    &:nth-child(n + 2) {
      border-top-width: 0;
    }
  }
}

