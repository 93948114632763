.field-other {
  .panel-body {
    padding: 12px;
  }

  &-title {
    margin-bottom: 12px;
  }

  p {
    margin: 0 0 12px 0;
  }

  &-edit-period {
    &-btns {
      margin-top: 20px;
    }
    &-error {
      margin-top: 10px;
      display: flex;
    }
  }

  &-btn {
    margin-bottom: 12px;
  }
}

.period-list {
  &-btn-add {
    color: #4cae4c;
  }
}

.period-item {
  display: flex;
  flex: 1;
  flex-direction: column;

  &-fields {
    display: flex;
    flex: 1;
    align-items: flex-start;

    > .form-group {
      display: flex;
      flex: 1;
      margin-right: 10px;
      flex-direction: column;
    }

    &-btn-delete {
      color: #d9534f;
      padding: 0;
      width: 35px;
      height: 35px;
      margin-top: 26px;
    }
  }
}
