$rotation-border-color: $color-gray-light;

.rotation-row {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  border: 1px solid $rotation-border-color;
  border-top: none;
  border-right: none;
  text-align: center;
  height: 100px;

  &--header {
    height: inherit;
    border-top: 1px solid #ececec;
  }

  .add-field {
    padding-right: 10px;
    border-right: 1px solid #ececec;
  }
}

.rotation-cell {
  border-right: 1px solid $rotation-border-color;
  flex: 1;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  text-align: left;

  &_bold {
    font-weight: bold;
  }

  &__link {
    word-break: break-all;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__wrapper {
    height: 100%;
  }

  &--first {
    flex: 2;
    overflow: hidden;

    .image {
      justify-content: space-evenly;

      &__ttn-thumb__img {
        margin: 0 20px;
      }
    }
  }

  &--disabled {
    background-color: rgb(155 155 155 / 20%);
  }

  &--active {
    background: #d8e8d8;
  }

  &__text {
    color: #777;
    margin: 0;
  }

  &--click {

    &:hover,
    &:focus {
      background: #eee;
      cursor: pointer;
    }
  }

  &__pencil {
    margin: 5px;
  }

  &__thumb {
    margin: 0 10px 0 0 !important;
  }

  &__crop {
    content: '';
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .Select {
    width: 100%;
  }
}

.rotation-create-update-modal {
  &__note {
    font-size: 12.5px;
    color: #9b9b9b;
  }

  &__crop-select_fix-margin {
    margin-bottom: 6px;
  }

  &__button-wrap {
    display: flex;
    margin-top: 12px;
    gap: 8px;
  }

  .btn {
    margin-right: 0;
  }
}

.rotation-filter {
  display: flex;
  padding: 6px 16px 5px 16px;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;
    flex: 1;
  }
}
