.topic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topic-comments {
  height: 100%;
  overflow-y: auto;
}
