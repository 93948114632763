.add-recommendation-modal {


    &__part {
        flex: 1;

        &_flex-2 {
            flex: 2
        }

        &_right {
            margin-right: 24px;
        }

        & textarea {
            min-height: 261px !important;
        }
    }

    &__buttons {
        margin-top: 8px;

        & div {
            display: flex;
            justify-content: center;
        }
    }
}