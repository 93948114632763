.support {
  display: flex;
  color: $color-gray;
  flex-direction: column;
  width: 400px;
  padding: 10px;
  border: 1px solid $color-gray-light;

  &__text {
    font-size: 13px;
  }
}
