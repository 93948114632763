.customer-editor-clients {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .works-mixture__btn-group {
    margin-bottom: 0 !important;
  }

  margin-bottom: 24px;
}

.customer-editor-clients-client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  &__field {
    flex: 1;
  }

  &__btns {
    margin-top: 26px;
    margin-left: 10px;
  }
}

.customer-editor-clients-btn {
  display: flex;

  & .btn-active-color {
    color: #4dae4c;
  }

  & .btn-danger-color {
    color: #e31a1c;
  }
}