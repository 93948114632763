.field-toggle-view {
  position: absolute;
  top: 8px;
  left: calc((100% - 300px)/2);
  transform: translate(-50%, 0);
  z-index: 999;
  margin: 0;
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    gap: 6px;
    background-color: #333533;
    margin-right: 6px;
    color: #ffffff;
    display: flex;
    cursor: pointer;
    line-height: 16px;
    width: 150px;

    &_active {
      background-color: #000000;
      color: #ffffff;

      & i {
        color: #069c61;
      }
    }

    &:hover {
      background-color: #000000;
      color: #ffffff;
    }

    &-img {
      width: 24px;
    }

    &-img, &-icon {
      font-size: 18px;
    }

    &_disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }
}
