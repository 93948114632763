.audit-events {
    padding: 12px;

    &-link {
        color: #069c61;
        text-decoration: underline;

        &:hover {
            color: #069c61;
            text-decoration: underline;
        }
    }

    & .ant-pagination-item-link {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }

    &-filters {
        display: flex;
        flex: 1;
        gap: 6px;

        &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex: 1;
            gap: 6px;
            &-item {
                width: 300px;
            }
        }
    }
}
