.panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;

  &-info {
    padding: 0.5rem 1rem 0 1rem;
  }

  .panel-body {
    flex: 1;
    overflow-y: auto;
    padding: 0;

    &--overflow-hidden {
      overflow: hidden;
    }

    .loader {
      margin: 10px;

      i {
        margin: 0 4px;
      }
    }
  }

  &--tab-list {
    //Should have header and footer
    height: 100%;
    margin: 0;
    position: relative;

    .panel-body {
      overflow-y: auto;
      position: absolute;
      top: 0;
      bottom: 41px;
      width: 100%;
    }

    .panel-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.not-found {
  margin: 15px;
}
