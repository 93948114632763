.machinery {
  border: 1px solid $color-gray-light;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__base-field {
    flex: 1;
  }

  &__fields {
    border-bottom: 1px solid $color-gray-light;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .form-group {
      width: 100%;
      padding: 0 5px;
    }

    &--column {
      flex-direction: column;
    }
  }

  &__btn-group {
    .btn-primary-color {
      color: #4cae4c;
    }

    .btn-danger-color {
      color: #d9534f;
    }
  }
}

.machinery-fields {
  flex: 1;
}
