.select-menu-list-virtualized {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  //padding-bottom: 4px;
  //padding-top: 4px;
  box-sizing: border-box;
  &-option {
    overflow: hidden;
    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
