.comments-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comments {
  overflow-y: auto;
}

.comment {
  display: flex;
  padding-bottom: 16px;
}

.comment-image {
  width: 40px;
  height: 40px;
  background-color: $color-green;
  text-align: center;
  border-radius: 50% !important;
  margin-right: 16px;

  &__initials {
    position: relative;
    top: 10px;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
  }
}

.comment-main {
  flex: 1;
}

.comment-name-date {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__name {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 4px;
  }

  &__date {
    font-size: 12px;
    color: $color-gray-text;
  }
}

.comment-text {
  font-size: 12px;
}

.comments-input {
  height: 48px;

  &__button {
    position: relative;
    margin-left: 6px;
    width: 32px;

    & .loader-circle {
      margin: 0 auto;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 14px;
    }
  }

  form {
    height: 100%;
  }

  input,
  textarea {
    box-shadow: none;
    height: 100%;
  }

  button {
    height: 100%;
    border-radius: 4px;
  }
}

.comments-input-panel {
  display: flex;
  align-items: center;
  height: 100%;
}

.comment-button {
  color: #000;
  background: #f5cb5c;
  font-size: 12px;
  margin-left: 10px;
}

.comment-close {
  text-align: right;

  i {
    margin: 0 10px;
    cursor: pointer;
  }
}

.comment-button-container {
  position: absolute;
  right: 24px;
  bottom: 0;
  margin-bottom: 32px;
}
