.documents {
  padding: 0;
}

.document {
  display: flex;
  align-items: center;
  padding-bottom: 16px;

  &__download {
    cursor: pointer;
  }

  &__remove {
    padding-left: 10px;
    color: #d9534f;
  }
}
