.works-filter {
  display: flex;
  align-items: center;

  &__select {
    width: 200px;
    padding: 5px;

    &_large {
      width: 300px;
    }
  }

  &__breadcrumbs {
    margin-left: 12px;

    & .breadcrumb {
      margin-bottom: 0;
    }
  }
}

.works-filter-with-add-works {
  display: flex;
  padding: 8px 16px;
}

.works-row {
  cursor: pointer;
}

.works-crop {
  &__circle {
    content: '';
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }
}

.works-type {
  display: flex;
  align-items: center;
  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    color: #069c61;
  }
}

.workrecords-crop,
.workrecords-type {
  font-size: 16px;
  margin-right: 10px;
}

.workrecords-back-wrap {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
}

.workrecords-crop-type {
  display: flex;
}

.workrecords-back {
  &__title {
    color: #069c61;
    font-size: 16px;
    text-decoration: underline;
  }
}

.workrecords-field {
  display: flex;
  align-items: center;
  &__image {
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin-right: 10px;
  }
}

.svg-image {
  width: 100%;
  height: 100%;

  & use {
    fill: inherit !important;
  }

  & svg {
    width: inherit;
    height: inherit;
  }
}

.works-trigger {
  visibility: hidden;
  min-height: 10px;
}
