.scout-image-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.scout-image-main {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  &__img {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
  }
}

.scout-image-thumb-container {
  flex-wrap: wrap;
  display: flex;
  width: 50%;
  overflow-y: auto;
  max-height: fit-content;
  margin: 5px;
}

.scout-image-thumb {
  padding: 1px;
  width: 50px;
  height: 50px;
  margin-right: 5px;
  margin-bottom: 5px;

  &--active {
    background: $color-green;
  }

  &__img {
    cursor: pointer;
    object-fit: none;
    width: 100%;
    height: 100%;
  }
}
