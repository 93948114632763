.scouts-statuses {
  margin: 10px;

  h4 {
    display: flex;
    align-items: center;
  }

  &__info {
    margin-left: 8px;
    font-size: 0.8em;
    cursor: help;
    color: RoyalBlue;
  }

  .notneeded {
    color: #069c61;
  }

  .needed {
    color: #ebbe47;
  }

  .expired {
    color: #ea5567;
  }

  .never {
    color: #666666;
  }
}
