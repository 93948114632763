.browser-support {
    &__wrap {
        width: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__logo {
        margin-bottom: 24px;

        & img {
            height: 300px;
        }
    }

    &__header {
        font-size: 36px;
        margin-bottom: 24px;
        max-width: 500px;
        text-align: center;
        font-weight: bold;
    }

    &__text {
        font-size: 16px;
        margin-bottom: 48px;
        font-weight: 500;
        color: grey;
        max-width: 600px;
        text-align: center;
    }

    &__browsers {
        display: flex;
        gap: 64px;

        & img {
            width: 80px;
        }
    }
}