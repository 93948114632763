.edit-prevent-product-modal {
  &__paragraph {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    text-align: justify;
  }

  &__wrapper {
    padding: 12px;
  }
}
