.tabs-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  & .nav-item {
    border-radius: 0;
    margin-right: 0;
    color: #9b9b9b;
    height: $panel-height;
    padding: 0 15px;
    line-height: $panel-height;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #069c61;
      background-color: #fff;
      color: #555;
      cursor: default;
    }
  }

  &_full-height {
    height: 100%;
  }
}
