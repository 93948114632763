$side-width: 500px;

.layout-field>.panel>.panel-body {
  background-color: #f6f6f6;
  padding-top: 10px;
}

.field__elepsis {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit-field-map {
  &__wrap {
    position: relative;
  }

  &__buttons {
    margin-top: 12px;
  }

  &__button {
    margin-right: 6px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &_base-map {
      z-index: 100;
      top: 2px;
      right: 310px;
    }
  }

  &__control {
    height: 36px;
    background: #f9f9f9;
    opacity: 0.9;
    border-radius: 4px;
    margin-top: 18px;
    background-repeat: no-repeat;
    background-position: 4px center;
    padding-left: 28px;
    padding-right: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #000;
    line-height: 29px;

    &.ruler-distance {
      margin-right: 6px;
      padding-left: 6px;
      background: rgba(255, 255, 255, 0.6)
    }

    &_wrap {
      display: flex;
      margin-top: 6px;

      & .edit-field-map__control {
        margin-top: 0;
      }
    }

    &.hole {
      width: 154px;
      background-size: 16px;
    }

    &.circle {
      width: 144px;
      background-size: 16px;
    }


    &.polygon {
      width: 154px;
      background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>%3C/svg>');
    }

    &.trash {
      width: 154px;
      background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z"/>%3C/svg>');
    }

    &.ruler {
      width: 154px;
      padding-left: 4px;

      &.active .fa {
        color: #f5cb5c
      }


      & .fa {
        font-size: 12px;
        margin-right: 7px;
      }
    }
  }
}

.field-container-vds {

  &__checkbox {
    margin-top: 4px;
    display: flex;
    align-items: center;

    & label {
      font-weight: 400;
      margin: 0;
    }
  }
}

.field-container {
  display: flex;
  margin: 0 5px;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: auto;

  .field-main-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow-y: auto;
    background-color: rgb(246, 246, 246);

    @media (max-width: 768px) {
      overflow-y: initial;
    }
  }

  .field-main {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    border-radius: 4px;
    background: $color-white;
    flex-basis: calc(33% - 10px);
    height: 100%;
    flex: 1;
    overflow-x: hidden;

    @media (max-width: 768px) {
      flex-basis: calc(100% - 10px);
      overflow-y: initial;
    }

    &--wide {
      flex: 2;
    }

    &--outer {
      margin: 0;
      flex: 3;
    }
  }

  .field-info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    p {
      margin: 0;
    }

    .divider {
      height: 1px;
      width: 100%;
      display: block;
      /* for use on default inline elements like span */
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }

    .nav {
      width: 100%;

      >li>a {
        padding: 2px 5px;

        &.active,
        &:hover,
        &:active,
        &:focus {
          background-color: #eee;
        }
      }
    }

    &__subtitle {
      color: $color-gray-text;
      font-size: 14px;
    }

    &__button-group {
      display: inline-block;
      margin: 0 0 0 10px;
      text-align: right;
      float: right;

      button {
        border: none;
      }

      .btn-primary-color {
        color: #337ab7;
      }

      .btn-danger-color {
        color: #d9534f;
      }
    }

    &__container {
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
    }

    .image {
      &__ttn-thumb {
        width: 100px;

        &__img {
          margin: 0 5px;
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .field-rotation {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-gray-light;
    border-bottom: 1px solid $color-gray-light;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    font-size: 16px;
    padding: 8px 16px;
    min-height: 66px;
    outline: none;

    &--active,
    &:hover {
      border: 1px solid $color-green;
    }

    &--disabled {
      background-color: rgb(155 155 155 / 20%);
    }

    &__year {
      font-weight: bold;
      text-align: left;
    }

    &__edit {
      text-align: right;
      cursor: pointer;
    }

    &__info-year {
      display: flex;
      align-items: center;
    }
  }

  .field-rotation-block {
    display: flex;
    justify-content: space-between;
  }

  .field-rotation-description {
    font-size: 14px;
    color: $color-gray-text;
    margin-top: 4px;
  }

  .field-image-group {
    display: table-cell;
    vertical-align: middle;
    padding-right: 16px;

    .image {
      &__ttn-thumb {
        width: 75px;

        &__img {
          margin: 0 5px;
          width: 75px;
          height: 75px;
        }
      }
    }
  }

  .field-scouts-history-btn {
    margin: 5px;
    height: 34px;
    width: 150px;
  }

  .list-group-item {
    &--active {
      background: #d8e8d8;
    }
  }

  .field-scouts-list {
    overflow: auto;
    flex: 3;
    margin-bottom: 0;
    padding: 0 16px;

    &_virtual-list {
      padding: 0 0 0 16px;
    }

    > li {
      list-style-type: none;
      border-bottom: 1px solid $color-gray-light;
    }
  }

  .field-scout-container.panel {
    .panel-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  .field-title-container {
    width: 100%;

    &:after {
      content: '';
      display: block;
      margin: 0 16px;
      border-bottom: 1px solid $color-gray-light;
    }
  }

  .field-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-group {
      margin-right: 16px;
    }
  }

  .field-title-icon-wrapper {
    padding: 10px 16px 9px 0;
    overflow: hidden;

    i {
      padding: 10px 15px;
      color: #9b9b9b;
      cursor: pointer;
    }
  }

  .field-title {
    margin: 13px 16px;
    font-size: 16px;
    color: $color-gray-text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_scout_elepsis {
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &_long-margin-left {
      margin-left: 32px;
    }

    &_black {
      color: #333;
    }

    &__plan_yield {
      font-size: 14px;
      font-weight: normal;
      margin-top: 6px;
    }

    &_scout {
      display: flex;
      justify-content: space-between;

      & i {
        color: #069c61;
      }
    }

    &--field {
      color: $color-green;
      display: inline-block;
      float: left;
      max-width: 100%;
      margin: 4px 0 4px 16px;
    }
  }

  .field-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .list-group-item {
      border: none;
    }
  }
}

.field-select-panel {
  .list-group {
    height: 400px;
  }
}

.field-rotation-info {
  display: flex;
  align-items: center;

  &__crop {
    content: '';
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    line-height: 8px;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;
  }
}

.field-icon.field-icon {
  width: 14px;
  height: 14px;
  margin: 4px;
  cursor: pointer;

  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__marker-icon {
    margin: 4px 1px 1px;
    width: 17px;
    height: 17px;
  }

  &_cadaster {
    margin: 0 6px 0 0;
  }

  &__ruler {
    margin: -4px 0 0 3px;
    width: 20px;
    height: 20px;
  }
}

.field-map {
  height: 150px;
  width: 100%;

  &_height {
    height: 100%;
  }

  &__full-height {
    height: 80vh;
  }

  &__half-height {
    height: 50vh;
  }
}

.field-scout {
  &__scouts {
    display: table-row;
    margin: 0 16px;
  }

  .scout-info {
    display: table-cell;
    vertical-align: middle;
    height: 102px;
    overflow: hidden;
  }
}

.field-scout-title {
  a {
    display: inline;
    align-items: center;
    color: $color-green;
    text-decoration: none;

    & * {
      display: inline;
    }

    .field-scout-title-date {
      text-decoration: underline;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 10px;
      box-sizing: border-box;
    }

    .field-scout-web {
      font-size: 14px;
      padding-right: 4px;
    }
  }

  &__icon {
    margin-right: 8px;
  }
}

.field-scout-description {
  font-size: 14px;
  line-height: 22px;

  p {
    margin: 0;
  }

  &__stage {
    white-space: nowrap;
    overflow: hidden;
    padding-right: 5px;
    text-overflow: ellipsis;
    width: 180px;
    max-width: 180px;
  }
}

.field-scout-problems {
  display: flex;
  align-items: center;

  &__block {
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.field-ts-trials-result__wrapper {

  & .field-name-link {
    color: #0d6efd;
  }
}

.field-ts-trials__wrapper {
  & .ant-table-tbody>tr>td:not(:first-of-type) {
    border-left: 1px solid #e8e8e8;
    height: 1px;
    padding: 0 16px;
  }

  & .field-name-link {
    color: #0d6efd;
  }
}

.field-ts-trials-result__filters-line-wrap {
  width: 100%;
}

.field-ts-trials-result__empty-select {
  color: hsl(0, 0%, 60%);
  padding: 12px 12px;
  text-align: center;
  box-sizing: border-box;
}

.field-ts-trials-result__wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1230px;

  .field-ts-trials-result__page-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 12px 24px;
    gap: 24px;
  }

  .field-ts-trials-result__table {
    flex: 1;
    margin-bottom: 24px;
  }

  .field-ts-trials-result__marker {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #1890ff;
  }

  .field-ts-trials-result__map-wrap {
    display: flex;
    gap: 24px;

    & .filled-ts__user-statistic-wrap {
      flex: 1;
    }

    & .filled-ts__user-statistic-item {
      width: 180px;
      height: 133px;
      text-align: center;
      border: 0;

      & .analytics-division__title {
        height: 63px;
      }
    }
  }

  .field-ts-trials-result__map {
    min-width: 266px;
    max-width: 266px;
    max-height: 266px;
    min-height: 266px;
    flex: 1;
  }

  .field-ts-trials-result__statistic {
    display: flex;
    width: 400px;
    padding: 6px;
    gap: 12px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    background-color: #fff;
  }

  .field-ts-trials-result__statistic-item {
    display: flex;
    gap: 4px;
    flex-direction: column;
    flex: 1;

    &:first-of-type {
      max-width: 65px;
    }
  }

  .field-ts-trials-result__statistic-item-count {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .field-ts-trials-result__statistic-item-count-text {
    font-size: 12px;
    text-align: center;
  }

  .field-ts-trials-result__filters {
    display: flex;
    width: 100%;
    gap: 6px;

    &-item {
      flex: 1;
    }

    &-btn-wrap {
      display: flex;
      justify-content: flex-end;
      flex: 0.5;
      align-items: flex-start;
    }
  }

}

.pull-right_long {
  margin-right: 32px !important;
}
