.filters-breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 6px 12px;
  background: #eee;

  &_multi {
    .breadcrumb > li > span {
      cursor: pointer;
    }
  }

  .breadcrumb {
    background: #eee;
    margin-bottom: 0;
    > li {
      > a {
        cursor: pointer;
        color: #069c61;
        &::selection {
          color: #069c61;
          background: #eee;
        }
      }
      > span {
        &::selection {
          color: #069c61;
          background: #eee;
        }
      }
    }
  }
  .active {
    font-weight: bold;
  }
}

.breadcrumb-item.breadcrumb-item.breadcrumb-item {
  & a {
    color: #9b9b9b;
    text-decoration: underline;
  }

  &.active {
    color: #069c61;
    font-weight: 500;
    text-decoration: underline;
  }
}