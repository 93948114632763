.advisor-analytics {
  height: 100%;
  width: 100%;

  .breadcrumb {
    background: $color-white;
    margin-bottom: 0;
    border-radius: 0;
  }
}

.advisor-analytics-counts {
  list-style: none;
  margin: 0;
  display: flex;
  padding: 0;

  &__item {
    font-size: 16px;
    margin-right: 20px;
  }
  &-wrapper {
    .clients {
      padding-top: 5px;
      line-height: 26px;

      &__item {
        text-decoration: underline;
      }
    }
  }
}

.advisor-analytics-by-region-counts {
  list-style: none;
  padding: 8px 0;
  margin: 0;

  &__item {
    display: flex;
    align-items: center;

    .advisor-analytics-counts__item {
      font-size: inherit;
      margin-right: 10px;
    }
  }

  &__name {
    margin-right: 10px;
  }
}

.advisor-analytics-counts-wrapper {
  padding: 8px 15px 16px 15px;
}

.advisor-analytics-link {
  outline: none;
  cursor: pointer;
  text-decoration: underline;
}
