.add-works-btn {
  margin: 5px;
}

.add-works-form-step-3-item {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__error {
    margin-bottom: 10px;
    display: block;
    color: #700;
  }

  &__machinery {
    display: flex;
    flex-direction: column;
    flex: 1;

    .machinery-fields {
      display: flex;
      flex-direction: row;

      &__item {
        flex: 1;
      }
    }
  }
}

.add-works-form-step-3-item-top {
  display: flex;
  flex-direction: row;
  flex: 1;

  &__field-info {
    width: 150px;
    padding-top: 13px;
  }
}
