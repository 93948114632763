.visits {
  padding: 0;
  &-list__trigger {
    visibility: hidden;
  }
}

.visits-filters__wrapper {
  display: flex;
  gap: 16px;
  align-items: flex-end;

  &_breeds {
    align-items: flex-start;
    padding: 12px 16px 0;
    gap: 6px;

    & .visits-filters__item {
      flex: 1;
    }

    & .visits-filters__item-btn {
      flex: 0.5;
      display: flex;
      justify-content: flex-end;
    }
  }


  & .visits-filters__item {
    width: 200px;

    &  .form-group {
      margin-bottom: 0;
    }

    & .react-datepicker__input-container .form-control {
      padding: 0.43rem 0.75rem;
    }
  }
}

.visit {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd solid;
  padding-bottom: 16px;
  padding-top: 16px;

  &:first-of-type {
    border-top: 1px #ddd solid;
    margin-top: 15px;
  }

  & .background-image-lazy__image {
    height: 100%;
  }

  &__text-block {
    max-width: 500px;
  }

  &__delete-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 12px;
  }

  &__edit-icon {
    position: absolute;
    top: 8px;
    right: 32px;
    cursor: pointer;
  }
}

.visit-image {
  height: 150px;
  width: 300px;

  &__blank {
    height: 100%;
  }
}


.visits-table-page {
  .filters-breadcrumbs {
    padding: 6px 16px;
  }
  &__header {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 8px;
    margin-left: 16px;
    margin-top: 16px;
  }

  &__cell {
    display: flex;
    align-items: center;

    & i {
      margin-right: 4px;
    }
  }

  &__paragraph {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: 16px;
    max-width: 468px;
  }

  &__icon {
    font-size: 16px;
    color: #BEBEBE;
    top: 20px;
    left: 16px;
    position: absolute;

    &-wrap {
      position: relative;
    }
  }

  &__th-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #069C61;
    margin-left: 4px;
  }

  &__th-name-img {
    margin-bottom: 3px;
  }

  &__input {
    padding: 17px 0 17px 44px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: 0;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-radius: 0;
  }

  & .ant-table-header th {
    padding: 12px 16px;

    & .ant-table-header-column {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #9B9B9B;
    }
  }
}

.visits-filters__btn-clear {
  border: 1px solid #ccc;
}