.logo {
  display: flex;
  height: 24px;

  &_width {
    width: 220px;
    height: auto;
    margin-left: -10px;

    & img {
      width: 220px;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
