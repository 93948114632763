.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  background: $color-gray-light;
  border-left: 1px solid $color-gray-light;

  &__header {
    border-bottom: 1px solid $color-gray-light;
  }

  &__main {
    height: 100%;
    position: relative;
  }

  &__footer {
    flex: 0 0 25%;
    overflow-y: auto;
    border-top: 1px solid $color-gray-light;
  }
}
