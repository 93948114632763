$image-background: #010101;
$preview-panel-height: 80px;

.image-modal {
  margin: 0 auto; //For using as a modal item content
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: $image-background;


  & .background-image-lazy__image {
    width: auto;
    height: 100%;
  }

  &__container {
    width: 100%;
    height: 100%;
  }

  &__image {
    height: calc(100vh - 61px);
    width: 100%;
    &_padding {
      height: calc(100vh - 61px - #{$preview-panel-height});
    }
  }

  &__rotate,
  &__rotate:focus,
  &__rotate:active {
    z-index: 999;
    color: $color-white;
    border: 2px solid $color-white;
    border-radius: 5px;
    outline: none;
    position: absolute;
    bottom: 15px;
    background: transparent;
    opacity: 0.5;
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  &__rotate:hover {
    opacity: 1;
  }


  &__rotate--download {
    left: 84px;
  }

  &__rotate--left {
    left: 25px;
  }
  &__rotate--right {
    right: 25px;
  }
}

.image-modal-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: $color-black;
  height: $preview-panel-height;
}
