.modal-90vw {
  width: 90vw;
  max-width: 90vw;
}

.modal-motivation {
  display: flex;
  align-items: center;
  height: 100vh;
}

.modal-1100 {
  width: 1100px;
  max-width: 1100px;
}

.tracks {
  display: flex;
  flex-direction: row;
  height: 90vh;
  margin: -1rem;
  position: relative;

  &__close {
    position: absolute;
    top: 6px;
    right: 18px;
    font-size: 18px;
  }

  &__tracks-wrap {
    padding: 12px 24px;
    height: 100%;
    overflow: auto;
    flex: 1;
  }

  &__map-wrap {
    height: 100%;
    flex: 1;
    position: relative;
  }

  &__map {
    height: 100%;
    width: 100%;
  }

  &__map-loader {
    flex: 1;
    height: 100%;
  }

  &-links {
    &__title {
      margin-right: 12px;
    }

    &__link {
      text-decoration: underline;
      cursor: pointer;
      color: $color-green;
      outline: none;
    }
  }
}

.track-description {
  width: 100%;
  border: none;
  border-spacing: 0;

  &__tr {
    & td {
      padding-bottom: 24px  ;
    }
  }

  &-date-time {
    cursor: pointer;
    outline: none;
    border-bottom: 1px dashed;
    font-size: 16px;

    &_font {
      font-size: 14px;
    }

    &_margin {
      margin-right: 6px;
    }
  }

  td {
    vertical-align: top;

    &:first-child {
      padding: 0 10px 0 0;
      width: 30%;
    }

    &.track-description-about {
      padding-bottom: 12px;
      color: #9b9b9b;
    }
  }
}