.drop-zone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #ccc;
  border-style: dashed;
  background-color: #fafafa;
  color: #ccc;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  &_error {
    border-color: rgba(191, 0, 0, 0.4);
  }
}

.drop-zone-list {
  margin: 10px 0 10px 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }
}
