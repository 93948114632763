.machines-list {
  &__list {
    display: flex;
    margin-top: 8px;
  }

  &__change-item {
    color: #337ab7;
  }

  &__delete {
    margin-left: 12px;
    color: #e21b1b;
  }

  &__paragraph {
    margin-bottom: 4px;
  }
}
