.trials-container {
  background-color: rgb(246, 246, 246);
  padding-bottom: 10px;
}

.trials {
  display: flex;
  justify-content: space-between;
  background: $white;
  margin: 0 5px;
  border-radius: 4px;
  max-height: 80px;
}

.trials-item {
  margin: 10px 0 10px 16px;
  flex: 1;
  padding-right: 10px;
  &:not(:last-child) {
    border-right: 1px solid $color-gray-light;
  }
  &.trials-status {
    max-width: 290px;
    overflow: auto;
  }
}

.trials-edit {
  display: flex;
  align-items: flex-start;
  flex: 0;
  &__btn {
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.trials-detail {
  display: flex;
  &__icon {
    width: 14px;
    height: 14px;
    margin: 4px;
    cursor: pointer;
  }
  &__comment {
    flex: 1;
    overflow: hidden;
    &_grey {
      color: #9b9b9b;
    }
  }
}

.trials-close-info {
  &__default {
    margin-bottom: 20px;
  }
  &_warn {
    color: red;
  }
}

.trials-link {
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}
