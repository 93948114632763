#tooltip {
  opacity: 1;

  .tooltip-inner {
    text-align: left;
    background-color: $color-white;
    color: inherit;
    max-width: none;
    padding: 3px 12px;

    > div {
      margin: 5px 0;
      &:before {
        content: '';
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
      }

      &.notneeded:before {
        background: #069c61;
      }

      &.needed:before {
        background: #ebbe47;
      }

      &.expired:before {
        background: #ea5567;
      }

      &.never:before {
        background: #666666;
      }
    }
  }

  .tooltip-arrow {
    border-left-color: $color-white;
  }
}
