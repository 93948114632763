$list-group-footer-height: 22px;

form {
  .btn {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .btn-danger-color {
    padding: 6px 11px;
  }
}

.form-control {
  padding: 6px 0.75rem;
}

.add-form-wrapper>button {
  margin: 10px;
}

.datepicker-pooper {
  z-index: 10000;
}

.list-group {
  height: 100%;

  &_no-margin {
    margin: 0;
  }

  &_overflow-y {
    overflow-y: auto;
  }

  .btn-primary-color {
    color: #337ab7;
  }

  .btn-danger-color {
    color: #d9534f;
  }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: $list-group-footer-height;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #dee2e6;
    background: $white;
  }

  &_with-footer {
    height: calc(100% - #{$list-group-footer-height});
  }
}

.modal-body {
  &__header {
    margin-bottom: 10px;
  }
}

.modal-dialog {
  .required label:after {
    content: '*';
    color: #bf0000;
    font-weight: normal !important;
    font-size: 11px;
    vertical-align: top;
  }
}

.form-validation {

  &__prev-info {
    margin: 6px 0;

    &-title {
      font-weight: bold;
    }
  }

  & .form-element_mb-15 {
    margin-bottom: 15px;
  }

  &__collapse {
    margin-bottom: 15px !important;

    & .ant-collapse-header {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }

  &__plan-info-btn {
    color: #069c61;
    border-bottom: 1px dashed #069c61;
    margin-bottom: 12px;
    display: inline-block;
    line-height: 16px;
  }

  .form-group>span,
  >strong {
    border: none;
    font-weight: 700;
    color: #700;
  }

  .form-group>span:before,
  >strong:before {
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f06a';
    margin-right: 5px;
  }

  .required input {
    border-color: rgba(191, 0, 0, 0.4);
  }

  .form-group.hidden {
    display: none;
  }

  .form-group {
    margin-bottom: 15px;

    & .tooltips {
      margin-left: 6px;
    }

    .btn-group {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}

.form-group_with_photos>label {
  display: flex;
  align-items: center;

  & input {
    margin-right: 8px;
  }
}

input[type='checkbox'] {
  margin-right: 4px;
}
