.input-ui {
  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__clear-icon {
    fill: hsl(0, 0%, 70%);
    margin-right: 8px;
    cursor: pointer;

    &:hover {
      fill: hsl(0, 0%, 50%);
    }
  }

  &__prepend {
    height: 100%;
    padding: 0 4px 0 12px;
    color: #555;
    align-items: center;
  }

  &__input-wrap {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    display: flex;
    margin-bottom: 0;
    align-items: center;
    position: relative;

    &_disabled {
      border-color: hsl(0, 0%, 90%);
    }

    &_invalid {
      border-color: #700;
    }

    &_focused {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    }
  }

  &__input {
    flex: 1;
    width: 100%;
    height: 33px;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    color: #555;
    outline: none;
    font-weight: normal;

    &::placeholder {
      color: #999999;
    }

    &:disabled {
      background-color: hsl(0, 0%, 95%);
    }
  }

  &__textarea {
    height: auto;
    resize: none;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 700;
    white-space: nowrap;
    align-items: center;

    &_required {
      &:after {
        content: "*";
        color: #bf0000;
        font-weight: 400 !important;
        font-size: 11px;
        vertical-align: top;
      }
    }
  }

  &__error-empty {
    height: 20px;
  }

  &__error {
    margin-top: 2px;
    color: #700;
    min-height: 18px;
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
