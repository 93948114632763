.filter-panel {
  position: fixed;
  right: 300px;
  width: 282px;
  z-index: 10;
  border: 1px solid $color-gray-light;
  background-color: rgb(236, 236, 236);

  &_map-width {
    width: 320px;
  }

  &_map-full-width {
    width: 640px;
    display: flex;
    flex-wrap: wrap;

    & .buttons-block {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    & .filters-block {
      flex: 1;
    }

    & input::placeholder {
      color: #808080;
    }
  }

  .btn-group {
    width: 100%;
    padding: 5px;
  }

  .dropdown-menu {
    width: 100%;
  }

  &__input {
    width: 100%;
    padding: 5px;
  }

  &__select {
    width: 100%;
    padding: 5px;
  }

  &__checkbox {
    padding: 5px;
    display: flex;
    font-weight: normal;
    align-items: center;

    &_no-margin {
      margin: 0;
      padding-bottom: 1px;
    }

    & label {
      margin: 0;
    }
  }

  &__multiple-fields-line {
    display: flex;
    justify-content: space-between;
    padding: 5px;

    & .filter-panel__field {
      width: calc(50% - 5px);
    }

    & .react-datepicker-wrapper {
      width: calc(50% - 5px);
    }

  }

  &__field-footnote {
    font-size: 14px;
    line-height: 16px;
    padding: 0 5px;
    margin-bottom: 6px;
    color: rgb(0 0 0 / 46%);
  }

  &__button {
    margin: 5px;
    padding: 5px;

    &--active {
      background: rgba(245, 203, 92, 1);

      &:active,
      &:focus,
      &:hover {
        background: rgba(245, 203, 92, 1);
      }
    }
  }
}
