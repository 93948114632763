.field-status {
  margin: 5px 0;
  &:before {
    content: '';
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &--small:before {
    width: 7px;
    height: 7px;
    line-height: 7px;
    margin-right: 0;
    min-width: 7px;
  }

  &.notneeded:before,
  &.good:before {
    background: #069c61;
  }

  &.needed:before,
  &.normal:before {
    background: #ebbe47;
  }

  &.expired:before,
  &.bad:before {
    background: #ea5567;
  }

  &.never:before {
    background: #666666;
  }
}
