.season-plans-fields {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.season-plans-fields-main-container {
  display: flex;
  flex: 1;
}

.season-plans-fields-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 16px 16px 16px;

  .list-group {
    overflow-y: hidden;
  }
}

.season-plans-fields-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.season-plans-fields-not-found {
  height: 100%;
}

.season-plans__button {
  margin-right: 6px;
}

.season-plans__bayer-template {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ccc;

  & button {
    border: 0;
    background: #f5cb5c;
    padding: 4px 16px;
    font-size: 16px;
    border-radius: 2px;
  }
}