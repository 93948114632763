.map-draw-area {
  position: absolute;
  top: 70px;
  left: 10px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 3px;
  margin: 0;
  width: 185px;
  font-weight: bold;
  font-size: 14px;

  &_map-view {
    position: static;
    width: 251px;
    margin-bottom: 12px;
  }

  &_edit-map-view {
    position: static;
    margin-bottom: 12px;
    width: 196px;
  }

  &_error {
    color: rgb(206, 17, 38);
  }
}