.scout-recommendations {
  display: flex;
  flex-direction: column;
  background: $color-gray-light;
}

.scout-recommendations-header {
  display: flex;
  flex-direction: row;
  margin: 8px 16px 8px 16px;
  height: $panel-height;
}

.scout-recommendations-header-btn {
  border: none;
  background: white;
  height: 100%;
}

.scout-recommendations-list {
  overflow-y: auto;
  flex: 1;
  margin: 0;
  padding: 0 8px 8px 8px;
  list-style: none;
  &__trigger {
    visibility: visible;
  }
}

.scout-recommendations-list-item {
  display: flex;
  flex-direction: row;
  padding: 8px;
  margin: 8px;
  background: $color-white;
}

.scout-recommendations-list-item-left, .scout-recommendations-list-item-right {
  display: flex;
  flex-direction: column;
  padding: 8px;
  flex: 1;
}

.scout-recommendations-list-item-left {
  border-right: 1px solid $color-gray-light;
}

.scout-recommendations-list-item-block {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.scout-recommendation {
  display: flex;
  flex-direction: column;

  &__block {
    font-size: 14px;
  }
}
