.customer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  background-color: #eee;
  padding: 8px;
  /* override list-group */
  margin-bottom: 0;

  &s__length {
    padding: 0 16px 4px;
    background: #eeeeee;
  }

  &s__form-control {
    padding: 6px 16px;
  }

  &s__fast-fields {
    & .filters-breadcrumbs {
      padding: 6px 16px;
    }
  }

  &s__list .customer {
    padding: 4px 8px;
  }

  &s__region-select {

    width: 250px;

    &-wrap {
      background: #eeeeee;
      padding: 4px 16px 12px;
      display: flex;
      align-items: center;
    }

    &-checkbox {
      margin-left: 12px;
    }
  }

  &__item {
    margin: 8px;
    width: calc(50% - 16px);
    border-radius: 4px;
    border: none;
    /* override list-group-item */
    &:last-child {
      margin-bottom: 10px;
    }
    min-height: 120px;
  }

  &__name {
    color: #069c61;
    font-size: 16px;
    text-decoration: underline;
  }

  &__name-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &__count-area {
    display: flex;
    align-items: center;
    height: 30px;

    p {
      margin: 0 20px 0 0;
    }
  }

  &__regions {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__profile {
    color: #9b9b9b;
    font-size: 14px;
    text-decoration: underline;
    i {
      margin-right: 5px;
    }
  }
}
