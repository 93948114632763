.custom-option {
  padding: 8px 12px;

  &:hover {
    background-color: #deebff;
    cursor: pointer;
  }

  &-descr {
    font-size: 14px;
    line-height: 14px;
    margin-top: 4px;
    color: #9b9b9b;
  }
}