.crops-yield {
  margin-bottom: 20px;
}

.crops-yield-items {
  display: flex;
  flex-direction: column;
}

.crops-stat__header {
  margin: 48px 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: #777;
  font-weight: normal;

  &_margin-top-size {
    margin-top: 18px;
  }
}
