$padding: 16px;

.profile-visits-container {
  height: 100%;
  width: 100%;
  padding: calc(#{$padding} / 2);
  background: $color-gray-light;
  overflow-y: auto;
}

.profile-visits {
  padding: $padding;
  margin: calc(#{$padding} / 2);
  margin-bottom: $padding;
  background: $color-white;
  overflow-y: auto;

  &_flex {
    display: flex;
    justify-content: space-between;
  }
}

.profile-visits__add-button {
  height: 56px;
  border: 1px solid #eee;

  & span {
    margin-left: 6px;
  }

  &-wrap {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

.profile-visits__info {
  font-size: 16px;
  margin-bottom: 15px;
}

.profile-visits-tabs {
  margin: calc(#{$padding} / 2);
  background: #fff;

  .profile-visits {
    margin: 0;
  }
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 5px 0;
  }

  &__link {
    color: #069c61;
    font-size: 16px;
    text-decoration: underline;
    margin: 0 16px;

    i {
      margin-right: 5px;
    }
  }
}

.profile-header-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-info-map {
  display: flex;
  justify-content: space-between;
  margin-top: calc(#{$padding} / 2);
}

.profile-info-decision-maker {
  margin-top: $padding;
}

.profile-map {
  height: 250px;
  width: 500px;

  &_fix-dimensions {
    height: 254px;
    width: 250px;
    margin: -16px -16px -16px 0;
  }

  &__marker {
    color: #f5cb5c;

    &_agrokeep {
      padding: 4px;
      border-radius: 100%;
      background: linear-gradient(to top, rgb(99, 191, 241) 30%, rgb(176, 205, 73));

      & .weather-station {
        opacity: 1;
      }
    }

    i {
      font-size: 40px;
    }

    &--new {
      color: #0074d9;
    }
  }
}

.profile-name-icons {
  display: flex;
  align-items: center;

  .btn-group {
    margin-left: 10px;
  }
}

.profile-sowings-tabs {
  display: flex;
  align-items: center;
}

.profile-sowings-tab {
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;

  &--active {
    border-bottom: 1px solid green;
  }
}

.profile-sowings-tab-add {
  color: green;
  padding: 5px;

  & span {
    margin-left: 8px;
  }
}

.profile-sowing-info {
  padding: 10px 0;
}

.profile-sowing-pesticide {
  margin-right: 5px;
  padding: 0 5px;
}

.profile-sowing-crop {
  padding-bottom: 15px;
}

.products-input {
  padding: 0;
  border-radius: 0;
  width: 560px;
  padding-left: 15px;
  margin-top: 12px;

  &__input-wrap {
    height: 36px;
    padding: 12px 8px;
  }
}

.products-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 16px;

  & .breadcrumb {
    margin: 0;
  }
}

.product {
  &__edit-icon {
    cursor: pointer;
  }
}
