$font-family-sans-serif: 'Roboto', Arial, sans-serif;
$btn-link-color: #069c61;
$link-decoration: none;
$dropdown-link-active-bg: #e9ecef;
$dropdown-link-active-color: shade-color(#212529 , 10%);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-default {
    box-shadow: none !important;

    &:hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}
