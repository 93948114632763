.scout-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    line-height: 24px;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
  }

  &__description {
    margin-bottom: 12px;
    p {
      font-size: 14px;
      margin: 0;
    }
  }

  &__subtitle {
    font-size: 14px;
    color: $color-gray-text;
    margin-top: 0;
    margin-bottom: 0;

    &_recomendation {
      margin-top: 1em;
    }
  }

  &__main-scout-point {
    padding-left: 4px;
  }

  &__main-scout-point-wrap {
    margin-top: 4px !important;
  }

  &__problems {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    word-break: break-all;
  }

  &__recommendations {
    font-size: 14px;
  }

  &__notifications {
    font-size: 14px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.scout-info-quality-wrapper {
  .scout-info-quality:not(.field-status--icon) {
    &.field-status--small {
      &:before {
        display: none;
      }
      p {
        padding-left: 0;
      }
    }
  }
}

.scout-info-threat {
  p {
    margin: 0;
  }
  &--grey {
    font-size: 12px;
    color: $color-gray-text;
  }

  &--active {
    font-weight: bold;
  }
}

.scout-info-comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.scout-info-quality {
  display: flex;
  align-items: center;
  margin: 0;
  p {
    padding-left: 10px;
    margin-bottom: 0;
  }
}

.scout-info-quality-work {
  p {
    padding-right: 10px;
  }
  &--inline {
    &:before {
      margin-right: 10px;
    }
    & > div {
      display: inline-block;
      margin-left: 5px;
      i {
        padding: 0 5px;
      }
    }
  }
}
