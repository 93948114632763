.scout-map {
  width: 100%;
  height: 100%;

  &__marker {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: $color-white;
    border-radius: 50% !important;
    opacity: 0.5;

    .fa.fa-eye {
      color: $color-green;
      font-size: 15px;
    }
  }
}
