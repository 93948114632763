.users-panel {
  flex: 1;
  height: auto;
  overflow: hidden;
}

.users-list {
  flex: 1;
}

.user-item {
  &__loader-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &_button {
      text-decoration: underline;
      cursor: pointer;
      color: #0d6efd;
    }
  }

  &__loader-element {
    margin: 0;
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }

  &__loader-btns {
    opacity: 0.1;
    pointer-events: none;
  }
}

.create-user__btn-info {
  display: flex;
  align-items: center;
  
  &_disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  & .loader-circle {
    margin: 0;
    width: 20px;
    height: 20px;
    margin-right: 6px;

    & .loader-child::before {
      background: white
    }
  }
}