.notifications {
  overflow-y: auto;
  max-height: 414px;
  overflow-x: hidden;
}

.notification {
  display: flex;
  align-items: center;
  color: $color-gray;
  width: 400px;
  min-height: $panel-height;
  border: 1px solid $color-gray-light;
  padding: 4px 0;

  &__icon {
    font-size: 24px;
    color: $color-gray-text;
    padding: 0 10px;

    &--close {
      margin-left: auto;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  &__text {
    font-size: 12px;
    line-height: 13px;
  }

  &__title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  &__date {
    font-size: 12px;
    color: $color-gray-text;
  }
}

.notifications-clear-panel {
  padding: 12px 0;
  border-top: 1px solid $color-gray-light;
}

@-moz-document url-prefix() {
  .notification__icon--close {
    margin-right: 10px;
  }
}

.notification-badge {
  color: $color-white;
  display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding: 2px 5px; /* Add some padding so it looks nice */
  &__amount {
    background-color: $color-white;
    border-radius: 50% !important;
    width: 15px;
    height: 15px;
    color: $color-gray;
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
