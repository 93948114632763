.divisions-dropdown {
  &__group-children {
    padding: 3px 20px 3px 28px !important;
  }
}

.divisions-collapse {
  display: flex;
  align-items: center;
  padding: 3px 20px;
  &:hover {
    background-color: #f5f5f5;
  }

  &__icon {
    width: 20px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: 0 !important;
    }

    &:hover,
    &:focus {
      color: $color-green;
    }
  }
}

.divisions-dropdown_no-caret:not(.divisions-container) {
  pointer-events: none;

  &::after {
    display: none;
  }
}

.divisions-container {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 10px);
  margin: 5px;

  &.form-group_division-dropdown {
    width: 100%;
    margin: 0;
  }

  .btn-group {
    width: 100%;
  }
  button {
    &:hover,
    &:focus,
    &:active {
      background: #fff;
      border-color: #ccc;
    }

    &.non-header {
      background: #fff !important;
      border-color: #ccc !important;
      outline: none !important;
      box-shadow: none !important;
      span {
        border-color: #999 transparent transparent;
        border-width: 5px 5px 2.5px;
      }
    }

    &.form-group_division-dropdown {
      margin: 0 !important;
      width: 100% !important;
      &:hover {
        border-color: hsl(0, 0%, 70%)  !important;
        .caret {
          border-color: hsl(0, 0%, 70%) transparent transparent;
        }
      }

      .caret {
        border-color: hsl(0, 0%, 80%) transparent transparent;
        border-width: 5px 5px 2.5px;

        &:before {
          content: '';
          display: flex;
          width: 1px;
          height: 16px;
          background: hsl(0, 0%, 80%);
          margin-top: -11px;
          margin-left: -19px;
        }
      }
    }
  }
}

.divisions-dropdown__wrapper .dropdown-menu {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
