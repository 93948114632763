.customer-filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 6px 12px;
  background: #eee;

  .breadcrumb {
    background: #eee;

    .active {
      font-weight: bold;
    }
  }
}
