$square-size: 200px;

.stage {
  display: flex;
  padding: 10px;
  height: 100%;

  &--init-height {
    height: initial;
  }

  &__image-container {
    display: flex;
    justify-content: center;
  }

  &__image {
    height: $square-size;
    width: $square-size;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &__header_no-margin {
    margin-bottom: 4px;
  }

  &__crop-items {
    margin-bottom: 14px;
  }

  &_pesticide-wrapper {
    padding: 10px 20px !important;
  }

  &_pesticide-before-registration {
    color: #FF6600;
  }

  &__content {
    flex: 1;
    padding: 0 10px;
    display: flex;
    height: 100%;
    flex-direction: column;

    h3 {
      margin-top: 0;
    }

    .panel {
      box-shadow: none;
      border: none;
      height: inherit;
    }

    & strong {
      margin-bottom: 12px;
    }
  }

  &-form-image {
    width: $square-size;
    height: $square-size;
    margin-bottom: 15px;
  }

  &-form-image-preview {
    max-width: 100%;
    max-height: 100%;
  }
}

.stage-image-preview {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
}