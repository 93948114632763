.list-item {
  display: flex;
  height: 66px;
  background: #ececec;
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid $color-gray-light;

  &--active,
  &:hover,
  &:focus {
    background: $color-white;
  }

  &__image-container {
    display: flex;
    justify-content: center;
  }

  &__image {
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin-right: 10px;

    &_empty {
      margin-left: 20px;
    }
  }

  &__content {
    overflow: hidden;
    width: 100%;

    > div,
    > p {
      margin: 5px 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__title {
    font-weight: bold;
    position: relative;

    .nowrap {
      padding-right: 50px;
    }
  }

  &__dscription {
    overflow-x: hidden;
  }

  &__name-icon {
    display: flex;
    align-items: center;

    img {
      margin-right: 2px;
    }

    i {
      color: rgba(255, 217, 0, 0.7);
      margin-right: 5px;
      font-size: 12px;
      &.fa-desktop {
        color: #000;
      }
    }
  }

  &__container {
    padding-left: 10px;
    overflow-y: auto;
  }

  &__description_grey {
    color: #9b9b9b;
  }

  &__ndvi-icon {
    &:before {
      display: inline-block;
      float: left;
      font-family: 'Font Awesome 5 Free';
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\f06c';
      margin-right: 3px;
      color: #069c61;
      font-weight: bold;
    }
  }

  &__ndvi-icon-no-color {
    &:before {
      display: inline-block;
      float: left;
      font-family: 'Font Awesome 5 Free';
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\f06c';
      margin-right: 3px;
      font-weight: bold;
    }
  }

  &__ndvi {
    position: absolute;
    width: 48px;
    text-align: center;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: normal;

    &_relief {
      position: absolute;
      width: 52px;
      text-align: center;
      top: 0;
      right: 0;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      align-items: center;
      &-icon {
        width: 14px;
        margin-right: 6px;
      }
    }
  }

  &-circle {
    font-size: 8px;
    margin-right: 8px;
  }
}
