.portal-dropdown-list {
  position: absolute;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding: 5px;
}

.portal-dropdown-btn {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  min-width: 20px;
}
