.link {
    text-decoration: underline;
    cursor: pointer;
    color: #069c61;

    &:hover {
         color: #069c61;
         text-decoration: underline;
     }
}
