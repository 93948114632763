.zoom-control {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  border: none !important;
  box-shadow: none !important;
  z-index: 0 !important;
  button {
    &:last-child {
      margin-top: 18px !important;
      background-position: 0 -36px !important;
    }
    border-radius: 50% !important;
    width: 36px !important;
    height: 36px !important;
    background-size: 36px 360px !important;
  }
}

.zoom-bounds {
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  > button {
    background-color: rgb(249, 249, 249);
    margin-top: 18px;
    border-radius: 50%;
    padding: 0;
    opacity: 0.9;
    outline: none;
    border: 0;
    width: 36px;
    height: 36px;

    &:hover,
    &:focus {
      opacity: 0.95;
    }
  }
}
