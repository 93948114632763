$color-black: #111;
$color-gray: #333533;
$color-gray-light: #ddd;
$color-gray-dark: #bbbcb7;
$color-gray-text: #9b9b9b;
$color-gray-light: #ececec;
$color-logo-yellow: #f5cb5c;
$color-green: #069c61;
$color-blue: #5ad0ea;
$color-white: #ffffff;
$color-black: #000000;

$panel-height: 46px;
$header-height: 56px;
$items-sidebar-width: 300px;

/* Layout */
$layout-content-background-color: $color-white;
$layout-content-wrapper-background-color: $color-gray;
$layout-header-background-color: $color-gray;
$layout-footer-background-color: $color-gray;
$layout-header-color: $color-white;
$layout-footer-color: $color-white;

/* Panel */
$panel-border-color: $color-gray-dark;
$panel-footer-background-color: $color-gray-dark;
$panel-header-background-color: $color-gray-light;
$panel-content-background-color: $color-gray-light;
