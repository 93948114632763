.label-with-select-unit {
  margin-bottom: 5px;

  &__unit {
    text-decoration: underline;
  }
}

.label-with-selected-unit {
  margin-bottom: 5px;
}
