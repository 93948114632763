$item-size: 40px;

.color-list-item {
  display: flex;
  align-items: center;

  &__color-block {
    width: $item-size;
    height: $item-size;
    margin-right: 10px;
  }
}
