.map-style-preview-modal {
  margin-left: 0;
  padding-left: 0;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.map-style-preview-selector {
  position: absolute;
  top: 8px;
  left: 10px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 3px;
  margin: 0;
  width: 251px;

  &_small {
    width: 196px;
  }
}

.map-style-selector {
  list-style: none;
  display: flex;

  &__header {
    margin-bottom: 3px;
    margin-left: 3px;
    font-weight: bold;
    font-size: 14px;
  }

  &__item {
    margin: 0 3px;

    button {
      border: 1px solid #ccc;
      background: #ddd;
      outline: none;
      font-size: 14px;
    }

    &--active button {
      background: #eee;
    }
  }
}

.map-preview {
  margin-top: 8px;
  margin-left: 3px;
  display: flex;
  align-items: center;

  &__no-top {
    margin-top: 0;
  }

  &_no-top-margin {
    margin-top: 0;
  }

  label {
    margin-bottom: 0;
    max-width: 209px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.map-preview__label_no-elepisis {
      white-space: normal;
      margin-left: 4px;
    }
  }
}