.reports-form {
  width: 400px;
  padding: 10px;

  &__error {
    white-space: pre-line;
  }

  &__custom-option {
    padding: 8px 12px;

    &:hover {
      background-color: #deebff;
      cursor: pointer;
    }

    &-descr {
      font-size: 12px;
      line-height: 12px;
      margin-top: 4px;
      color: #9b9b9b;
    }
  }

  &__button {
    margin-bottom: 10px;
    .loader-circle {
      margin: 0;
    }
  }
}

.reports-form-loader {
  display: flex;
  align-items: center;
  &__text {
    margin-left: 10px;
    color: $color-gray-text;
    margin-bottom: 10px;
  }

  .loader-circle {
    flex-shrink: 0;
  }
}
