.layout {
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  &_column {
    flex-direction: column;
  }

  &__sidebar {
    width: $items-sidebar-width;
    height: calc(100% - #{$header-height});
    position: fixed;
    top: $header-height;
    right: 0;
    z-index: 1000;

    &--no-shift {
      top: inherit;
    }
  }

  &__main {
    position: relative;
    width: calc(100% - #{$items-sidebar-width});
    overflow-y: auto;
    &_full {
      width: 100%;
    }
  }
}
