.plots-container {
  background-color: rgb(246, 246, 246);
  padding-bottom: 10px;

  .customer-filters {
    background: #fff;
    padding: 6px 0;
    font-size: 16px;
    height: 43px;
    width: auto;

    .breadcrumb {
      background: #fff;
    }
  }
}

.plots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin: 0 5px;
  border-radius: 4px;

  &__report {
    border-left: 1px solid #ececec;
    padding-left: 10px;
    margin-left: 10px;
  }

  .plots-navigation {
    padding: 10px 16px;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    flex: 1;
    display: flex;

    .btn {
      min-height: 51px;
      display: inline;
      outline: none !important;
      margin-right: 16px;
      text-align: left;
      &:last-of-type {
        margin-right: 0;
      }
      &.active {
        .size {
          color: #fff;
        }
      }
    }

    .size {
      font-size: 12px;
      color: #9b9b9b;
    }
  }
}

.plots-btn-group {
  padding: 0 15px;
  display: flex;
}

.create-plots-link-container {
  height: 38px;
}

.create-plots-link {
  text-decoration: underline;
  cursor: pointer;
  color: $color-green;
  padding: 0 0 16px 16px;
  outline: none;
}

.create-update-plot {
  display: flex;
}

.plots-photos-text {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 6px;
}

.plots-photo {
  width: 70px;
  height: 70px;
  &__inner {
    height: 100%;
  }
}

.plots-photos-editor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  &__photos {
    min-height: 400px;
  }
}

.plots-photos-editor-images {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  &__image {
    border: 1px solid transparent;
    &_active {
      border-color: $color-green;
    }
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    padding: 5px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.plots-photos-viewer {
  display: flex;
  flex-direction: column;
  &__item {
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.plots-photos-viewer-item {
  display: flex;
  align-items: center;
  &__icon {
    width: 14px;
    height: 14px;
    margin: 4px;
    cursor: pointer;
  }
  &__photo {
    margin-right: 10px;
  }
}

.plots-report {
  &__btn {
    display: flex;
    &_disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.plots-report-form {
  &__btn {
    margin-top: 20px;
  }
}

.plots-report-form-btns {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.plots-report-form-error {
  color: red;
}
