.analytics-container {
  height: 100%;
  width: 100%;

  .breadcrumb {
    background: #fff;
    margin-bottom: 0;
    border-radius: 0;

    >li>a {
      color: #069c61;
    }
  }
}

.analytics-scouting {
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  & .recharts-yAxis .recharts-cartesian-axis-tick-value {
    text-decoration: underline;
    cursor: pointer;
  }

  &__graphs-wrapper {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }

  &__graph-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .loader-circle {
    margin: 0;
  }

  &__title {
    color: #9b9b9b;
  }

  &__link a {
    color: black;
    text-decoration: underline;
  }

  &__phase {
    padding-left: 10px;
  }
}

.analytics-work-quality {
  padding: 5px 15px;
  position: relative;

  &__chart-title {
    width: 242px;
    text-align: center;
    font-size: 16px;
    color: #777;
    font-weight: normal;
  }

  &__chart-titles {
    display: flex;
    margin-top: 12px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    color: #777;
  }

  .quality-item {
    margin: 8px 0;

    .work-type {
      color: $color-black;
    }

    .work-quality-item {
      display: inline-block;
      padding: 0 0 0 4px;
    }
  }

  .alert-field-id {
    display: inline;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .loader-circle {
    margin: 10px 0;
  }
}

.analytics-breadcrumbs {
  margin-top: 12px;
  padding: 0 15px;

  &_margin-bottom {
    margin-bottom: 6px;
  }

  .breadcrumb {
    >li {
      font-size: 16px;
      line-height: 24px;

      >a {
        color: #069c61;
        text-decoration: underline;
      }
    }
  }
}

.analytics-title {
  display: flex;
  align-items: center;

  &__icon {
    font-size: 18px;
    color: #069c61;
    margin: -10px;
    padding: 10px;
    padding-top: 22px;
    cursor: pointer;
    padding-left: 16px;
  }
}

.analytics-info {
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;

  &__dark {
    color: rgb(51, 51, 51);
  }
}

.analytics-divisions {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 15px 0;
}

.analytics-division {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 180px;
  padding: 16px;
  margin: 0 15px;
  cursor: pointer;

  &_center {
    align-items: center;
  }

  &__loader {
    width: 36px !important;
    height: 36px !important;
    margin: 0 auto !important;
  }

  &:hover,
  &:focus {
    box-shadow: 0 1px 5px $color-gray-light;
  }

  &__count {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  &__title {
    text-align: center;
  }

  &__name {
    color: #069c61;
    text-decoration: underline;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
}

.analytics-crops {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 196px;
  margin-top: 24px;

  &_advisor {
    max-height: 290px;
  }

  &__legend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:first-of-type) {
        margin-left: 24px;
      }
    }

    &-rect {
      width: 10px;
      height: 10px;
      margin-right: 12px;
    }
  }

  &__item {
    width: 250px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }

  &__name {
    text-decoration: underline;
    cursor: pointer;
  }

  &__description {
    color: #9b9b9b;
    margin-left: 24px;
  }

  &__graphs {
    display: flex;
    flex-direction: column;
  }

  &__graph {
    display: flex;

    &:first-of-type {
      margin-top: 12px;
    }

    &:not(:first-of-type) {
      margin-top: 36px;
    }
  }

  &__table {
    margin-bottom: 20px;
  }
}

.analytics-crops-lowercase {
  text-transform: lowercase;
}

.analytics-scout-problems {
  margin-bottom: 20px;
}

.analytics-scout-problems__wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 1112px;
}

.analytics-scout-problems__item-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-top: 12px;

  &:nth-of-type(3) {
    margin-top: 24px;
  }
}

.analytics-scout-problems__item-title {
  font-size: 16px;
  min-height: 24px;
  display: flex;
  margin-bottom: 12px;
  color: #777;
}

.analytics-scout-problems__custom-tooltip {
  padding: 12px;
  border: 1px solid;
  background: white;
}

.analytics-scout-problem {
  margin-bottom: 12px;

  p {
    margin: 0;
  }
}

.analytics-scout-pie-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.analytics-scout-pie-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.analytics-scout-pie {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  border: 1px solid lightskyblue;
  background-image: conic-gradient(lightskyblue var(--degree), white 0 360deg);
}

.analytics-crops-pesticide {
  margin-bottom: 20px;
}

.analytics-report {
  text-decoration: underline;
  cursor: var(--cursor);
}

.analytics-graph__axis-info {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-bottom: 24px;
}

.analytics-products {
  &__name {
    display: flex;
    justify-content: space-between;
  }
}
