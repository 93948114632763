.filled-ts {
    &__header {
        margin: 12px 24px;
    }

    &__filter {
        width: 240px;
    }

    &__filters {
        margin: 24px;
        display: flex;
        gap: 24px;
    }

    &__user-statistic {
        &-wrap {
            display: flex;
            align-items: center;
            gap: 24px;
            margin: 12px 24px;

            &_no-margin {
                margin: 20px 0;
            }
        }

        &-item {
            border: 1px solid #c4c4c4;
            border-radius: 4px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 180px;
            padding: 16px;

            &_stretch {
                align-self: stretch;
                border: 0;
            }
        }
    }

    &__pointer {
        cursor: pointer;
    }

    &__table-wrap {
        margin: 24px;
    }

    &__table-color-td {
        height: 100%;
        margin: -16px -16px;
        padding: 16px;
        display: inline-table;
        width: calc(100% + 32px);

        &.red {
            background: #f4b084;
        }

        &.yellow {
            background: #ffd966;
        }

        &.green {
            background: #a9d08d;
        }

        &.dark-green {
            background: #00b050;
        }
    }
}
