.image-carousel {
  position: relative;
  .carousel-control {
    width: 50px;
    height: 50px;
    top: 50%;
    margin-top: -25px;
    background: transparent;
    border: 3px solid $color-white;
    border-radius: 5px;

    &.right {
      right: 15px;
    }
    &.left {
      left: 15px;
    }

    .glyphicon-chevron-right,
    .icon-next,
    .glyphicon-chevron-left,
    .icon-prev {
      margin-top: -16px;
    }

    .glyphicon-chevron-right,
    .icon-next {
      margin-right: -15px;
    }

    .glyphicon-chevron-left,
    .icon-prev {
      margin-left: -15px;
    }
  }
}

.images-modal {
   padding: 30px;

  .modal-dialog {
    max-width: initial;
    width: auto !important;
    height: 100%;
    margin: 0 auto;

    .modal-content {
      height: 100%;

      .modal-body {
        padding: 0;

        .image-carousel {
          margin: 0;
        }
      }
    }
  }

  &__close {
    width: 50px;
    height: 50px;
    border: 2px solid $color-white;
    border-radius: 5px;
    font-size: 26px;
    line-height: 28px;
    color: $color-white;
    position: fixed;
    top: 60px;
    right: 60px;
    z-index: 1000;
    background: transparent;
    opacity: 0.5;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.images-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    margin: 5px 0;
  }
}
