.zoom-button {
  background-color: #f9f9f9;
  opacity: 0.95;
  transition: background-color 0.16s ease-out;
  cursor: pointer;
  border: 0;
  height: 26px;
  width: 26px;
  background-image: url('https://api.mapbox.com/mapbox.js/v2.4.0/images/icons-000000@2x.png');
  background-position: 0px 0px;
  background-size: 36px 360px;
  outline: 0;

  &.plus {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.minus {
    background-position: 0px -36px;
  }

  &:hover {
    background-color: #fff;
    opacity: 1;
  }
}
