.ruler {
    &__wrap {
        left: 10px;
        z-index: 999;
        background: rgba(255, 255, 255, 0.7);
        padding: 5px 6px;
        margin: 0;
        width: 251px;
        min-height: 50px;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 12px;



        &.edit-field {
            top: 158px;
            width: 196px;
        }

        &.tracks-ruler {
            top: 74px;
            width: 196px;
            position: absolute;
        }

        &.field-satellite-map {
            top: 6px;
            position: absolute;
            height: 94px;
        }

        &.field-satellite-map_multiple {
            left: 212px !important;
            top: 6px;
            left: 0;
            width: 204px;
            background: white;
            position: absolute;
            height: 94px;
        }
    }

    &__button {
        text-decoration: underline;
        margin-top: 12px;
    }

    &__distance {
        &_error {
            color: rgb(206, 17, 38);
        }
    }
}
