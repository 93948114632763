$sat-scenes-height: 80px;
$sat-scenes-full-height: 84px;
$sat-icon-hight: 45px;

.sat-container {
  position: absolute;
  bottom: 6px;
  left: 10px;
  z-index: 999;
  padding: 5px 3px;
  margin: 0;
  width: calc(100% - 320px);
  display: flex;
  align-items: flex-end;

  >* {
    border-right: 1px solid #ddd;
    background-color: $color-white;
    cursor: pointer;
  }

  &.sat-container__no-cursor > * {
    cursor: default;
  }

  &__left {
    position: static;
    // background: #d7d7d7;
    flex-wrap: wrap;
    align-content: flex-start;
    max-width: 205px;
    padding: 0;

    &>div {
      width: 100%;
    }

    &>.sat-scenes-container {
      width: calc(100% - 17px);

      &+div {
        width: 17px;
      }
    }

    & .sat-scenes-list-wrapper {
      position: absolute;
      top: 70px;
      width: 205px;
      background: white;
      max-height: calc(100% - 80px);
      z-index: 1001;
      flex-direction: column;
    }

    & .field-satellite-map__toggle-view-btn {
      position: absolute;
      width: 205px;
      bottom: 0;
      left: 0;
    }
  }
}

.sat-icon {
  &__glyph {
    width: $sat-icon-hight;
    height: $sat-icon-hight;
    margin: 17px 17px 18px;
    cursor: pointer;

    .fa {
      font-size: 44px;
    }
  }

  &__loding {
    position: relative;
  }

  &__process {
    position: absolute;
    bottom: 4px;
    left: 44px;
    font-size: 10px;
  }
}

.sat-products {
  display: flex;
  flex: 1;
  align-items: center;
  height: $sat-scenes-height;
  color: #9b9b9b;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;

  .sat-sorter-wrapper {
    display: flex;
    height: 100%;
    max-height: 79px;
    flex: 1;
    align-items: center;

    .sat-product {
      display: block;

      &-switch {
        display: inline-block;
      }

      &-hint {
        display: inline-block;
      }
    }

    .sat-sorter {
      &-menu-wrapper {
        display: flex;
        align-items: center;
        cursor: auto;
        border-left: 1px solid #ddd;
        height: 100%;
        max-width: 351px;
        min-width: 210px;
        text-align: center;
      }

      &-menu {
        display: inline-block;
        max-width: 351px;
        text-align: center;
        padding: 10px 0;
      }

      &-menu-item {
        display: flex;
        flex-wrap: wrap;
        gap: 0 9px;
        padding: 0 9px;

        span:not(.sort-direction) {
          cursor: pointer;
          text-decoration: underline;
          outline: none !important;
        }
      }

      &-hint {
        display: inline-block;
        height: $sat-scenes-height;
        line-height: $sat-scenes-height;
        padding: 0 4px 0 0;
      }
    }
  }

  &-switch-wrapper {
    display: flex;
    height: 100%;
    flex: 1;
    align-items: center;
    padding-right: 4px;
    min-width: 190px;
  }
}

.sat-product {
  line-height: 22px;
  
  &s-switch {
    display: flex;
    flex-wrap: wrap;
    gap: 0 9px;
    padding: 0 9px;
  }

  &--active,
  &:hover {
    outline: 0;

    .sat-product__title {
      color: $color-green;
    }
  }
}

.sat-product__wrapper-multiple {
  display: flex;
  flex: 1;

  & .sat-product__items {
    display: flex;
    flex-wrap: wrap;
    gap: 0 9px;
    padding: 0 9px;
    min-width: 252px;
  }

  & .sat-product__info {
    display: flex;
    align-items: center;
    padding-right: 4px;
  }

  & .sat-product {
    line-height: 24px;
  }
}

.sat-scenes {
  min-height: $sat-scenes-height;
  max-height: $sat-scenes-height;
  height: $sat-scenes-height;
  min-width: 162px;
  padding: 8px;
  line-height: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;

  &_full-height {
    min-height: $sat-scenes-full-height;
    max-height: $sat-scenes-full-height;
    height: $sat-scenes-full-height;
  }

  &-container {
    border-right: none;
    position: relative;

    .sat-container__left & {
      position: static;
    }
  }

  &__overlay-bad-weather-text {
    line-height: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);
  }

  &__overlay-bad-weather {
    padding: 8px 0 8px 13px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    opacity: 0.6;
    cursor: pointer;
    min-height: 39px;

    &.Firefox {
      transform: scaleY(-1);
    }

    &-text {
      max-width: 100px;
      word-break: break-word;
      margin-left: 3px;
    }
  }

  &__title {
    padding: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__satellite {
    padding: 0 5px;
    color: #9b9b9b;
  }

  &__arrow {
    color: $color-logo-yellow;
  }

  &.Firefox {
    transform: scaleY(-1);
    min-width: 170px;
  }

  &-hint {
    border-bottom: 1px solid #ddd;
    padding-right: 4px;
    height: $sat-scenes-height;
    line-height: $sat-scenes-height;
  }
}

.sat-scenes-values {
  display: flex;
  color: #9b9b9b;

  &__value {
    padding: 0 5px;
    display: flex;
    align-items: center;

    & .list-item__ndvi-icon {
      font-size: 12px;
      margin-right: 3px;
    }
  }
}

.sat-scene {
  &--disabled {
    opacity: 0.7;
  }

  & .field-icon {
    margin-left: 0;
  }
}

.sat-scenes__field-preview {
  margin-right: 6px;
  height: 63px;

  & img {
    width: 60px;
    max-height: 63px;
  }
}

.sat-scenes-list-wrapper {
  max-height: calc(#{$sat-scenes-height} * 5);
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;

  .sat-container:not(.sat-container__left) & {
    min-width: 166px;
    position: absolute;
    background: white;
    display: block;
    transform: scaleY(-1); // that's for bug https://gitlab.agromon.ru/agromon/dev/frontend/-/issues/1069, chrome column-reverse with overflow
    bottom: 79px;

    & .sat-scenes,
    & .sat-scenes__overlay-bad-weather {
      transform: scaleY(-1); // that's for bug https://gitlab.agromon.ru/agromon/dev/frontend/-/issues/1069, chrome column-reverse with overflow
    }
  }

  &.Firefox {
    flex-direction: column;
    transform: scaleY(-1);
    overflow-x: hidden;
  }

}

.sat-scale {
  display: flex;
  flex-direction: column;
  height: 80px;
  min-width: 240px;
  justify-content: flex-end;
  padding: 8px 8px 12px 8px;
  border-bottom: 1px solid #ddd;


  &-list {
    display: flex;
    flex-direction: row;

    &__item {
      width: 22px;
      height: 18px;

      &_large-width {
        width: 74px;
      }

      &_middle-width {
        width: 20px;
      }
    }
  }

  &-values {
    display: flex;
    justify-content: space-between;

    &__item {
      color: #9b9b9b;
    }
  }
}

.sat-management-zone-type {
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  width: 195px;

  &__wrapper {
    display: flex;
    align-items: center;
    padding-right: 4px;
  }

  &__button {
    border: 0;
    background: transparent;
    line-height: 24px;
    text-align: left;
    padding: 0 6px;

    &_active {
      color: #069c61;
      cursor: default;
    }
  }
}

.sat-container__left {
  & .sat-product__items {
    min-width: auto;
  }

  & .sat-products_max-height {
    height: 100px;
    align-items: flex-start;
  }
}
