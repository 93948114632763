$padding: 16px;

.season-plans-container {
  display: flex;
  padding: 10px 5px 0 5px;
  flex-wrap: wrap;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  background: $color-gray-light;

  .season-plans-main {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    border-radius: 4px;
    background: $color-white;
    flex-basis: calc(33% - 10px);
    height: 100%;
    flex: 1;
    @media (max-width: 768px) {
      flex-basis: calc(100% - 10px);
    }

    &--wide {
      flex: 2;
    }
  }
}
